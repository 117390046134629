var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('div',[_c('v-container',{staticStyle:{"max-width":"1170px","margin":"0 auto"},attrs:{"grid-list-lg":"","text-xs-center":""}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.cells),function(cell,index){return _c('v-col',{key:index,staticClass:"pa-6",attrs:{"cols":"12","md":"4"}},[(cell.status === 'coming')?_c('v-card',{staticStyle:{"background":"#fff","box-shadow":"0 2px 10px #e2e2e2","text-align":"center"}},[_c('v-card-text',{staticClass:"px-0"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":("assets/i/" + (cell.name) + ".jpg")}}),_c('h3',{staticStyle:{"text-transform":"uppercase","margin":"10px"}},[_vm._v(" "+_vm._s(cell.name)+" ")]),_c('br'),_vm._v(" Acrylic, canvas, 40cmx50cm "),_c('v-divider',{staticStyle:{"margin":"5px"}}),_c('b',[_vm._v("Coming soon")]),_c('v-divider',{staticStyle:{"margin":"5px"}}),_vm._v(" Start day: "+_vm._s(cell.dateStart)+" ")],1)],1):(
              cell.status === 'stop' ||
                cell.status === 'finish' ||
                cell.status === 'close'
            )?_c('v-card',{staticStyle:{"background":"#fff","box-shadow":"0 2px 10px #e2e2e2","text-align":"center"},attrs:{"to":("/auction/" + (cell.id))}},[_c('v-card-text',{staticClass:"px-0"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":("assets/i/" + (cell.name) + ".jpg")}}),_c('h3',{staticStyle:{"text-transform":"uppercase","margin":"10px"}},[_vm._v(" "+_vm._s(cell.name)+" ")]),_c('v-btn',{staticStyle:{"margin":"15px","width":"80%","height":"auto","padding":"15px"},attrs:{"to":("/auction/" + (cell.id)),"color":"blue-grey lighten-2"}},[_vm._v("RESULT")]),_c('br'),_vm._v(" Acrylic, canvas, 40cmx50cm "),_c('v-divider',{staticStyle:{"margin":"5px"}}),_c('b',{staticClass:"orange--text"},[_vm._v("Auction finished!")]),_c('v-divider',{staticStyle:{"margin":"5px"}}),_vm._v(" End: "+_vm._s(cell.dateEnd)+" "),_c('v-divider',{staticStyle:{"margin":"5px"}}),_vm._v(" Highest bid - "+_vm._s(cell.cost)+" "),_c('br'),_c('b',[_vm._v(" By: "),_c('span',{attrs:{"title":cell.account}},[_vm._v(" "+_vm._s(_vm._f("labelAddress")(cell.account))+" ")]),(
                    _vm.$robonomics.account &&
                      cell.account === _vm.$robonomics.account.address
                  )?[_vm._v(" (your bid) ")]:_vm._e()],2)],1)],1):_c('v-card',{staticStyle:{"background":"#fff","box-shadow":"0 2px 10px #e2e2e2","text-align":"center"},attrs:{"to":("/auction/" + (cell.id))}},[_c('v-card-text',{staticClass:"px-0"},[_c('div',{staticClass:"frame"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":("assets/i/" + (cell.name) + ".jpg")}})]),_c('h3',{staticStyle:{"text-transform":"uppercase","margin":"10px"}},[_vm._v(" "+_vm._s(cell.name)+" ")]),_c('v-btn',{staticStyle:{"margin":"15px","width":"80%","height":"auto","padding":"15px"},attrs:{"to":("/auction/" + (cell.id)),"color":"success"}},[_vm._v("BID")]),_c('br'),_vm._v(" Acrylic, canvas, 40cmx50cm "),_c('v-divider',{staticStyle:{"margin":"5px"}}),_c('b',{staticClass:"green--text"},[_vm._v("Auction started!")]),_c('v-divider',{staticStyle:{"margin":"5px"}}),_vm._v(" End: "+_vm._s(cell.dateEnd)+" "),_c('v-divider',{staticStyle:{"margin":"5px"}}),_vm._v(" Highest bid - "+_vm._s(cell.cost)+" "),_c('br'),_c('b',[_vm._v(" By: "),_c('span',{attrs:{"title":cell.account}},[_vm._v(" "+_vm._s(_vm._f("labelAddress")(cell.account))+" ")]),(
                    _vm.$robonomics.account &&
                      cell.account === _vm.$robonomics.account.address
                  )?[_vm._v(" (your bid) ")]:_vm._e()],2)],1)],1)],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }